.input {
  border-radius: 12px;
  box-shadow: inset 0 0 0 2px rgba(146, 159, 173, 0.5);
  font-size: 20px;
  height: 56px;
  line-height: 24px;
  margin-top: 20px;
  outline: none;
  padding: 0 20px;
  width: 100%;
}

.input::placeholder {
  color: #929fad;
}

.input:focus {
  box-shadow: inset 0 0 0 2px #477adf, 0 0 0 3px rgba(0, 85, 255, 0.2);
}

.primaryButton {
  margin-top: 20px;
}
