.authenticationBox {
  display: flex;
  flex-direction: column;
}

.logo {
  align-self: center;
}

.title {
  color: #193c80;
  font-size: 28px;
  font-weight: bold;
  margin-top: 40px;
}
