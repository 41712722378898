.primaryBox {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 4px -2px rgba(15, 50, 122, 0.2), 0 16px 64px -16px rgba(15, 51, 122, 0.4);
  left: 50%;
  max-width: 456px;
  padding: 40px 48px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.primaryBox.wide {
  max-width: 800px;
}
