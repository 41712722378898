.header {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.signOut {
  background-color: #c02669;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  height: 36px;
  line-height: 24px;
  outline: none;
  padding: 0 16px;
}

.signOut:focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.15), 0 0 0 3px rgba(192, 38, 105, 0.3);
}

.signOut:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.signOut:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.account {
  align-items: center;
  color: #193c80;
  display: flex;
  gap: 8px;
  margin-top: 32px;
}

.account h2,
.accountEmailAddress {
  font-family: "Encode Sans Condensed", sans-serif;
  line-height: 30px;
}

.account h2 {
  font-size: 20px;
  font-weight: bold;
}

.accountEmailAddress {
  font-size: 18px;
}

.arrivingParcel {
  align-items: center;
  background-color: #193b80;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  margin-top: 20px;
  padding: 12px 16px;
}

.parcelIcon {
  background-color: #fff;
  height: 32px;
  -webkit-mask: url("../../images/parcel.svg");
  mask: url("../../images/parcel.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  width: 32px;
}

.parcelIcon.small {
  height: 24px;
  width: 24px;
}

.arrivingParcelText {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
}

.parcelListTitle {
  color: #6f80a3;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 32px;
}

.parcelList {
  margin: 16px 0 0;
  padding: 0;
}

.parcelList li {
  display: flex;
  gap: 8px;
}

.parcelList li + li {
  margin-top: 16px;
}

.parcelList li .parcelIcon {
  background-color: #00997f;
  flex: none;
}

.parcelList li .parcelIcon.failed {
  background-color: #c02669;
}

.parcelList li .parcelListItemDate,
.parcelList li .parcelListItemDelivery {
  color: #193c80;
  font-size: 18px;
  line-height: 24px;
}

.parcelList li .parcelListItemDate {
  flex: none;
  width: 160px;
}

.parcelList li .parcelListItemDelivery {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
