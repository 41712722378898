.emailAddress,
.primaryButton {
  margin-top: 20px;
}

.emailAddress {
  color: #193c80;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 20px;
  line-height: 24px;
}

.secondaryButton {
  margin-top: 12px;
}
