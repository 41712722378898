.accountCreated,
.primaryButton {
  margin-top: 20px;
}

.accountCreated {
  color: #193c80;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 20px;
  line-height: 24px;
}
