@font-face {
  font-family: "Averta OP";
  font-weight: normal;
  src: url("../../fonts/averta-op-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Averta OP";
  font-weight: bold;
  src: url("../../fonts/averta-op-bold.woff2") format("woff2");
}

@font-face {
  font-family: "Encode Sans Condensed";
  font-weight: normal;
  src: url("../../fonts/encode-sans-condensed-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Encode Sans Condensed";
  font-weight: 600;
  src: url("../../fonts/encode-sans-condensed-600.woff2") format("woff2");
}

@font-face {
  font-family: "Encode Sans Condensed";
  font-weight: bold;
  src: url("../../fonts/encode-sans-condensed-bold.woff2") format("woff2");
}

@font-face {
  font-family: "PT Sans";
  font-weight: normal;
  src: url("../../fonts/pt-sans-regular.woff2") format("woff2");
}

@font-face {
  font-family: "PT Sans";
  font-weight: bold;
  src: url("../../fonts/pt-sans-bold.woff2") format("woff2");
}

@font-face {
  font-family: "PT Sans Narrow";
  font-weight: normal;
  src: url("../../fonts/pt-sans-narrow-regular.woff2") format("woff2");
}

@font-face {
  font-family: "PT Sans Narrow";
  font-weight: bold;
  src: url("../../fonts/pt-sans-narrow-bold.woff2") format("woff2");
}

* {
  box-sizing: border-box;
}

*:not(input) {
  user-select: none;
}

body {
  background-color: #e0ebff;
  font-family: "PT Sans", sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Sans Narrow", sans-serif;
  margin: 0;
  padding: 0;
}

input,
button,
select {
  background-color: transparent;
  border: none;
  font-family: inherit;
  padding: 0;
}

input {
  font-family: "PT Sans Narrow", sans-serif;
}

button {
  font-family: "Encode Sans Condensed", sans-serif;
}

.app {
  align-items: center;
  background: url("../../images/background.svg") no-repeat top center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 48px 120px;
}

.passageCallout {
  bottom: 48px;
  left: 120px;
  position: absolute;
  right: 120px;
}

.passageCallout h3,
.passageCallout p {
  font-family: "Averta OP", sans-serif;
}

.passageCallout h3 {
  color: #193c80;
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 120%;
}

.passageCallout p {
  letter-spacing: -0.16px;
  line-height: 150%;
  margin: 4px 0 0;
}

.passageCallout p a {
  color: #0364d3;
  font-weight: bold;
}
