.primaryButton {
  background-color: #00997f;
  border-radius: 12px;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  height: 56px;
  line-height: 24px;
  outline: none;
  padding: 0 20px;
  width: 100%;
}

.primaryButton:focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.15), 0 0 0 3px rgba(0, 153, 128, 0.3);
}

.primaryButton:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.primaryButton:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.primaryButton:disabled {
  background-color: #e0ebff;
  background-image: none;
  box-shadow: none;
  color: #193b80;
  cursor: default;
  font-weight: 400;
}
