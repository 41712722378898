.logo {
  align-items: center;
  display: flex;
}

.medium.logo {
  gap: 6px;
}

.large.logo {
  gap: 8px;
}

.icon {
  background-color: #193c80;
  -webkit-mask: url("../../images/parcel.svg");
  mask: url("../../images/parcel.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
}

.medium .icon {
  height: 36px;
  width: 36px;
}

.large .icon {
  height: 48px;
  width: 48px;
}

.text {
  color: #193c80;
  font-family: "Encode Sans Condensed", sans-serif;
  font-weight: bold;
}

.medium .text {
  font-size: 30px;
  line-height: 30px;
}

.large .text {
  font-size: 40px;
  line-height: 40px;
}
